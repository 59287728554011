import { DataSource } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { TaxonNode, TaxonTreeControl } from '@x/ecommerce/domain-data';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[xTaxonNodeDef]',
})
export class TaxonNodeDefDirective {
  constructor(private tree: TaxonTreeComponent, public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[xTaxonNodeActionDef]',
})
export class TaxonNodeActionDefDirective {
  constructor(private tree: TaxonTreeComponent, public template: TemplateRef<any>) {}
}

@Component({
  selector: 'x-taxon-tree',
  templateUrl: 'taxon-tree.component.html',
  styleUrls: ['taxon-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-taxon-tree',
  },
})
export class TaxonTreeComponent implements OnDestroy {
  @ContentChild(TaxonNodeDefDirective)
  treeNodeDef?: TaxonNodeDefDirective;

  @ContentChild(TaxonNodeActionDefDirective)
  treeNodeActionDef?: TaxonNodeActionDefDirective;

  @Input()
  set treeControl(treeControl: TaxonTreeControl) {
    if (this._treeControlSub) {
      this._treeControlSub.unsubscribe();
    }

    this._treeControlSub = treeControl.expansionModel.changed.subscribe(() =>
      this.changeRef.markForCheck(),
    );
    this._treeControl = treeControl;
  }
  get treeControl() {
    return this._treeControl;
  }

  @Input()
  dataSource: DataSource<TaxonNode>;

  private _treeControl: TaxonTreeControl;
  private _treeControlSub?: Subscription;

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnDestroy() {
    if (this._treeControlSub) {
      this._treeControlSub.unsubscribe();
    }
  }

  hasChild = (_: number, node: TaxonNode) => {
    return Array.isArray(node.children) && node.children.length;
  };

  trackById = (i: number, node: TaxonNode) => {
    return node;
  };
}
