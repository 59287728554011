import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProductService } from '@x/ecommerce/domain-client';
import {
  ProductOptionAutocompleteDataSource,
  TaxonAutocompleteDataSource,
} from '@x/ecommerce/domain-data';
import { CreateProductInput, ProductOptionObject } from '@x/schemas/ecommerce';
import { TaxonDialogService } from '../../services/taxon-dialog.service';

export interface ProductCreateDialogData {}

export interface ProductCreateDialogResult {
  deferTaxonCreate?: boolean;
  data?: { id: number; name: string };
}

@Component({
  selector: 'x-product-create-dialog',
  templateUrl: 'product-create-dialog.component.html',
  providers: [ProductOptionAutocompleteDataSource, TaxonAutocompleteDataSource],
})
export class ProductCreateDialogComponent implements OnInit {
  taxonControl = new UntypedFormControl(null, [Validators.required]);

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
    mainTaxon: this.taxonControl,
    variation: new UntypedFormControl('SINGLE', [Validators.required]),
    options: new UntypedFormControl([]),
  });

  constructor(
    public dialog: MatDialogRef<ProductCreateDialogComponent, ProductCreateDialogResult>,
    private taxonDialogs: TaxonDialogService,
    private productService: ProductService,
    public productOptionDataSource: ProductOptionAutocompleteDataSource,
    public taxonDataSource: TaxonAutocompleteDataSource,
  ) {}

  ngOnInit() {
    this.formGroup.get('variation')?.valueChanges.subscribe((v) => {
      if (v === 'OPTION') {
        this.formGroup.get('options')?.setValidators([Validators.required]);
      } else {
        this.formGroup.get('options')?.setValidators([]);
      }
    });
  }

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    const form = this.formGroup.value;

    const optionIds = form.options.map((option: ProductOptionObject) => option.id);

    const data: CreateProductInput = {
      mainTaxonId: form.mainTaxon?.id || null,
      name: form.name,
      variation: form.variation,
      optionIds,
    };

    this.productService.create(data).subscribe((data) => {
      this.dialog.close({ data });
    });
  }

  addTaxon() {
    this.taxonDialogs
      .openTaxonCreateDialog()
      .afterClosed()
      .subscribe((result) => {});
  }
}
