import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductFilterInput } from '@x/schemas/ecommerce';
import { DateTime } from 'luxon';
import {
  IProductAssociationFormDialogData,
  IProductAssociationFormDialogResult,
  ProductAssociationFormDialogComponent,
} from '../components/product-association-form-dialog/product-association-form-dialog.component';
import {
  ProductAttributeFormDialogComponent,
  ProductAttributeFormDialogData,
  ProductAttributeFormDialogResult,
} from '../components/product-attribute-form/product-attribute-form-dialog.component';
import {
  ProductCloneDialogComponent,
  ProductCloneDialogData,
  ProductCloneDialogResult,
} from '../components/product-clone-dialog/product-clone-dialog.component';
import {
  ProductCreateDialogComponent,
  ProductCreateDialogData,
  ProductCreateDialogResult,
} from '../components/product-create-dialog/product-create-dialog.component';
import {
  ProductExportDialogComponent,
  ProductExportDialogData,
} from '../components/product-export-dialog/product-export-dialog.component';
import {
  ProductOptionDialogData,
  ProductOptionDialogResult,
  ProductOptionFormDialogComponent,
} from '../components/product-option-form/product-option-form-dialog.component';
import {
  ProductSchedulePublishDialogComponent,
  ProductSchedulePublishDialogData,
  ProductSchedulePublishDialogResult,
} from '../components/product-schedule-publish-dialog/product-schedule-publish-dialog.component';

@Injectable()
export class ProductDialogService {
  constructor(private dialogs: MatDialog) {}

  openProductCreateDialog() {
    let dialog = this.dialogs.open<
      ProductCreateDialogComponent,
      ProductCreateDialogData,
      ProductCreateDialogResult
    >(ProductCreateDialogComponent);

    return dialog;
  }

  openProductCloneDialog(clone_id: number) {
    let dialog = this.dialogs.open<
      ProductCloneDialogComponent,
      ProductCloneDialogData,
      ProductCloneDialogResult
    >(ProductCloneDialogComponent, {
      data: { clone_id },
    });

    return dialog;
  }

  openProductOptionCreateDialog() {
    let dialog = this.dialogs.open<
      ProductOptionFormDialogComponent,
      ProductOptionDialogData,
      ProductOptionDialogResult
    >(ProductOptionFormDialogComponent);
    return dialog;
  }

  openProductOptionUpdateDialog(productOptionId: number) {
    let dialog = this.dialogs.open<
      ProductOptionFormDialogComponent,
      ProductOptionDialogData,
      ProductOptionDialogResult
    >(ProductOptionFormDialogComponent, {
      data: { productOptionId },
    });
    return dialog;
  }

  openProductAttributeCreateDialog() {
    let dialog = this.dialogs.open<
      ProductAttributeFormDialogComponent,
      ProductAttributeFormDialogData,
      ProductAttributeFormDialogResult
    >(ProductAttributeFormDialogComponent, { data: {} });
    return dialog;
  }

  openProductAttributeUpdateDialog(id: number) {
    let dialog = this.dialogs.open<
      ProductAttributeFormDialogComponent,
      ProductAttributeFormDialogData,
      ProductAttributeFormDialogResult
    >(ProductAttributeFormDialogComponent, {
      data: { id },
    });
    return dialog;
  }

  openProductSchedulePublishDialog(publishDate?: DateTime) {
    let dialog = this.dialogs.open<
      ProductSchedulePublishDialogComponent,
      ProductSchedulePublishDialogData,
      ProductSchedulePublishDialogResult
    >(ProductSchedulePublishDialogComponent, {
      data: { publishDate },
    });
    return dialog;
  }

  openProductReportDialog(filter: ProductFilterInput) {
    return this.dialogs.open<ProductExportDialogComponent, ProductExportDialogData>(
      ProductExportDialogComponent,
      {
        data: { filter },
      },
    );
  }

  openAssociationFormDialog(id?: number) {
    return this.dialogs.open<
      ProductAssociationFormDialogComponent,
      IProductAssociationFormDialogData,
      IProductAssociationFormDialogResult
    >(ProductAssociationFormDialogComponent, {
      data: { id },
      minWidth: '400px',
      maxWidth: '600px',
    });
  }
}
