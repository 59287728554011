import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportService } from '@x/ecommerce/domain-client';
import {
  ProductOptionAutocompleteDataSource,
  TaxonAutocompleteDataSource,
} from '@x/ecommerce/domain-data';
import { ProductFilterInput, ReportProductInput } from '@x/schemas/ecommerce';
import { IReportObject } from 'libs/ecommerce/src/domain-client/services/types/report';
import { BehaviorSubject } from 'rxjs';

export interface ProductExportDialogData {
  filter: ProductFilterInput;
}

export type ProductExportDialogResult = IReportObject;

@Component({
  selector: 'x-product-export-dialog',
  templateUrl: 'product-export-dialog.component.html',
  providers: [ProductOptionAutocompleteDataSource, TaxonAutocompleteDataSource],
})
export class ProductExportDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({
    locale: new FormControl<string | undefined>(undefined, [Validators.required]),
    includeAttributes: new FormControl<boolean>(true, [Validators.required]),
  });

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    public readonly dialog: MatDialogRef<ProductExportDialogComponent, ProductExportDialogResult>,
    @Inject(MAT_DIALOG_DATA) private readonly data: ProductExportDialogData,
    private readonly reportService: ReportService,
  ) {}

  ngOnInit(): void {
    this.loading$.subscribe((loading) => {
      if (!loading && this.formGroup.disabled) {
        this.formGroup.enable();
      }
      if (loading && this.formGroup.enabled) {
        this.formGroup.disable();
      }
    });
  }

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    this.loading$.next(true);

    const form = this.formGroup.value;

    const input: ReportProductInput = {
      locale: form.locale,
      productFilter: this.data.filter,
      options: {
        includeAttributes: form.includeAttributes,
      },
    };

    this.reportService.reportProducts(input).subscribe((result) => {
      this.dialog.close(result);
    });
  }
}
