import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonValidator, FormErrorBag } from '@x/common/form';
import { ProductOptionDetailObject, ProductOptionService } from '@x/ecommerce/domain-client';
import { ChannelContext } from '@x/ecommerce/domain-data';
import {
  CreateProductOptionInput,
  ProductOptionValueObject,
  UpdateProductOptionInput,
} from '@x/schemas/ecommerce';
import { GraphQLError } from 'graphql';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeepExtractTypeSkipArrays } from 'ts-deep-extract-types';

export type ProductOptionTranslationDetailObject = DeepExtractTypeSkipArrays<
  ProductOptionDetailObject,
  ['translations']
>;
export type ProductOptionValueDetailObject = DeepExtractTypeSkipArrays<
  ProductOptionDetailObject,
  ['values']
>;
export type ProductOptionValueTranslationDetailObject = DeepExtractTypeSkipArrays<
  ProductOptionDetailObject,
  ['values', 'translations']
>;

export interface ProductOptionDialogData {
  productOptionId?: number;
}

export interface ProductOptionDialogResult {
  deferTaxonCreate?: boolean;
  data?: { id: number; name: string };
}

interface ProductOptionFormState {
  loading: boolean;
  data?: ProductOptionDetailObject;
  errors?: ReadonlyArray<GraphQLError>;
}

@Component({
  selector: 'x-product-option-form-dialog',
  templateUrl: 'product-option-form-dialog.component.html',
})
export class ProductOptionFormDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  translationsGroup: UntypedFormGroup;
  valuesGroup: UntypedFormArray;
  locales$ = this.channelContext.getLocaleOptionsInAllChannels();

  formErrorBag$: Observable<FormErrorBag>;
  formObject$ = new BehaviorSubject<ProductOptionFormState>({ loading: true });

  isUpdate: boolean = false;

  constructor(
    public dialog: MatDialogRef<ProductOptionFormDialogComponent, ProductOptionDialogResult>,
    public productOptionService: ProductOptionService,
    private channelContext: ChannelContext,
    @Inject(MAT_DIALOG_DATA) public data: ProductOptionDialogData,
  ) {}

  trackById = (i: number, o: ProductOptionValueObject) => o.id;

  ngOnInit() {
    this.translationsGroup = new UntypedFormGroup({});
    this.valuesGroup = new UntypedFormArray([]);
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
      translations: this.translationsGroup,
      values: this.valuesGroup,
    });

    this.isUpdate = this.data?.productOptionId !== undefined && this.data?.productOptionId !== null;

    if (this.data?.productOptionId) {
      this.productOptionService
        .fetchDetail(this.data.productOptionId)
        .pipe(
          tap((option) => {
            this.formGroup.get('name')?.setValue(option.name);

            option.translations.forEach((trans) => {
              this.makeTranslationForm(trans.locale, trans);
            });
            [...option.values]
              .sort((a, b) => a.position - b.position)
              .forEach((val, index) => {
                this.makeValueGroup(val);
              });

            this.formObject$.next({
              loading: false,
              data: option,
            });
          }),
        )
        .subscribe();
    } else {
      this.addOption();
    }
  }

  submit() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) {
      console.warn('ProductOptionFormComponent: form invalid', this.formGroup.value);
      return;
    }

    const form = this.formGroup.value;

    if (this.data?.productOptionId) {
      // do update
      const data: UpdateProductOptionInput = {
        id: this.data.productOptionId,
        name: form.name,
        translations: Object.values(this.translationsGroup.value),
        values: this.valuesGroup.value.map((val: any) => ({
          id: val.id,
          name: val.name,
          translations: Object.values(val.translations),
          position: val.position,
        })),
      };

      this.productOptionService.update(data).subscribe((result) => {
        this.dialog.close({ data: result || undefined });
      });
    } else {
      // do create
      const data: CreateProductOptionInput = {
        name: form.name,
        translations: Object.values(this.translationsGroup.value),
        values: this.valuesGroup.value.map((val: any) => ({
          name: val.name,
          translations: Object.values(val.translations),
          position: val.position,
        })),
      };
      this.productOptionService.create(data).subscribe((result) => {
        this.dialog.close({ data: result || undefined });
      });
    }
  }

  hasTranslationGroup(locale: string) {
    return !!this.translationsGroup.get(locale);
  }

  getTranslationGroup(locale: string): UntypedFormGroup {
    if (!this.hasTranslationGroup(locale)) {
      this.addTranslation(locale);
    }
    return <UntypedFormGroup>this.translationsGroup.get(locale);
  }

  addTranslation(locale: string) {
    this.makeTranslationForm(locale);
  }

  private makeTranslationForm(
    locale: string,
    translation?: ProductOptionTranslationDetailObject,
  ): UntypedFormGroup {
    let group = new UntypedFormGroup({
      locale: new UntypedFormControl(translation?.locale || locale, []),
      name: new UntypedFormControl(translation?.name, [
        Validators.required,
        CommonValidator.trimmed(),
      ]),
    });
    this.translationsGroup.setControl(locale, group);
    return group;
  }

  makeValueGroup(value?: ProductOptionValueDetailObject): UntypedFormGroup {
    let group = new UntypedFormGroup({
      id: new UntypedFormControl(value?.id),
      name: new UntypedFormControl(value?.name, [Validators.required, CommonValidator.trimmed()]),
      translations: new UntypedFormGroup({}),
      position: new UntypedFormControl(value?.position ?? -1),
    });
    this.valuesGroup.push(group);

    value?.translations?.forEach((trans) => {
      this.makeValueTranslationGroup(trans.locale, this.valuesGroup.length - 1, trans);
    });
    return group;
  }

  getValueTranslationGroup(locale: string, index: number): UntypedFormGroup {
    return (
      ((
        (this.valuesGroup.at(index) as UntypedFormGroup)?.get('translations') as UntypedFormGroup
      ).get(locale) as UntypedFormGroup) ?? this.makeValueTranslationGroup(locale, index)
    );
  }

  makeValueTranslationGroup(
    locale: string,
    index: number,
    translation?: ProductOptionValueTranslationDetailObject,
  ): UntypedFormGroup {
    let group = new UntypedFormGroup({
      locale: new UntypedFormControl(translation?.locale || locale, []),
      name: new UntypedFormControl(translation?.name, [
        Validators.required,
        CommonValidator.trimmed(),
      ]),
    });
    (
      (this.valuesGroup.at(index) as UntypedFormGroup).get('translations') as UntypedFormGroup
    ).setControl(locale, group);

    return group;
  }

  addOption() {
    this.makeValueGroup();
  }

  copyCanonicalTo(locale: string) {
    this.getTranslationGroup(locale).patchValue({
      name: this.formGroup.get('name')?.value,
    });
    for (let i = 0; i < this.valuesGroup.controls.length; i++) {
      const group = this.valuesGroup.controls[i];
      const localeGroup = this.getValueTranslationGroup(locale, i);
      localeGroup.patchValue({
        name: group.get('name')?.value,
      });
    }
  }

  valueDropped(event: CdkDragDrop<any>) {
    const values = this.valuesGroup.value as Array<ProductOptionValueObject>;
    moveItemInArray(values, event.previousIndex, event.currentIndex);
    values.forEach((m, i) => (m.position = i + 1));
    this.valuesGroup.setValue(values);
  }
}
