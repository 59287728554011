import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  TaxonCreateDialogComponent,
  TaxonCreateDialogData,
  TaxonCreateDialogResult,
} from '../components/taxon-form/taxon-create-dialog.component';

@Injectable()
export class TaxonDialogService {
  constructor(private dialogs: MatDialog) {}

  openTaxonCreateDialog(parentId?: number) {
    return this.dialogs.open<
      TaxonCreateDialogComponent,
      TaxonCreateDialogData,
      TaxonCreateDialogResult
    >(TaxonCreateDialogComponent, { data: { parentId } });
  }
}
