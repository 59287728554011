<h2 mat-dialog-title>{{ isUpdate ? 'Edit' : 'Add' }} Product Attribute</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="product_attribute_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <div class="row">
        <mat-form-field appearance="fill" class="col">
          <mat-label>Attribute Name</mat-label>
          <input type="text" matInput formControlName="name" required />
          <mat-error xFormError="name"></mat-error>
        </mat-form-field>

        <ng-container *ngFor="let locale of localeOptions$ | async">
          <div
            *ngIf="formGroup.getTranslationGroup(locale.id) as group"
            [formGroup]="group"
            class="col"
          >
            <mat-form-field appearance="fill">
              <mat-label>{{ locale.id }}</mat-label>
              <input type="text" matInput formControlName="name" required />
              <mat-error [xFormError]="'translations.' + locale.id + '.name'"></mat-error>
            </mat-form-field>
            <button mat-button type="button" (click)="copyCanonicalTo(locale.id)">
              Copy canonical to {{ locale.id }}
            </button>
          </div>
        </ng-container>
      </div>

      <mat-form-field appearance="fill" class="col-6">
        <mat-label>Attribute Type</mat-label>
        <mat-select formControlName="type" required>
          <mat-option value="MARKDOWN">Markdown</mat-option>
          <!-- <mat-option value="TEXT">Text</mat-option>
          <mat-option value="BOOLEAN">Boolean</mat-option>
          <mat-option value="NUMBER">Number</mat-option> -->
        </mat-select>
        <mat-error xFormError="type"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col">
        <mat-label>Position</mat-label>
        <input type="number" matInput formControlName="position" required />
        <mat-error xFormError="position"></mat-error>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="product_attribute_form">Save</button>
</div>
