<h2 mat-dialog-title>Create Product</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="product_create_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Product Name</mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="disabled" />
        <mat-hint>Only visible to administrators</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          <fa-icon icon="layer-group"></fa-icon>
          Main Taxon
        </mat-label>
        <x-model-autocomplete
          formControlName="mainTaxon"
          [dataSource]="taxonDataSource"
          placeholder="Search taxon..."
          [displayWith]="taxonDataSource.displayFn"
        ></x-model-autocomplete>
      </mat-form-field>
      <!-- <div class="row align-items-center">

      </div>
      <button mat-icon-button matSuffix (click)="addTaxon()">
        <fa-icon icon="plus-square"></fa-icon>
      </button> -->

      <mat-form-field class="mt-4">
        <mat-label>
          <fa-icon icon="circle"></fa-icon>
          Product Variation
        </mat-label>
        <mat-select required formControlName="variation">
          <mat-option value="SINGLE">Single Variant</mat-option>
          <mat-option value="OPTION">Multi-Option Variants</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="fill"
        *ngIf="formGroup.get('variation')?.value === 'OPTION'"
        class="mt-4"
      >
        <mat-label>
          <fa-icon icon="list"></fa-icon>
          Select Options
        </mat-label>
        <x-model-autocomplete-chips
          formControlName="options"
          [dataSource]="productOptionDataSource"
          placeholder="Search product options..."
          [displayWith]="productOptionDataSource.displayFn"
        ></x-model-autocomplete-chips>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="product_create_form">Create</button>
</div>
