import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ProductAttributeDetailObject,
  ProductAttributeTranslationDetailObject,
} from '@x/ecommerce/domain-client';

export class ProductAttributeFormGroup extends UntypedFormGroup {
  constructor() {
    super({
      name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(32)]),
      type: new UntypedFormControl(null, [Validators.required]),
      position: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
      translations: new UntypedFormGroup({}),
    });
  }

  get translationsGroup(): UntypedFormGroup {
    return this.controls['translations'] as UntypedFormGroup;
  }

  init({ value }: { value?: ProductAttributeDetailObject }) {
    this.patchValue(
      {
        name: value?.name ?? null,
        type: value?.type ?? 'MARKDOWN',
        position: value?.position ?? 1,
      },
      { emitEvent: false },
    );

    value?.translations?.forEach((t) => this.makeTranslationGroup(t.locale, t));
  }

  getTranslationGroup(locale: string): UntypedFormGroup {
    return (
      (this.translationsGroup.get(locale) as UntypedFormGroup) ?? this.makeTranslationGroup(locale)
    );
  }

  makeTranslationGroup(
    locale: string,
    translation?: Partial<ProductAttributeTranslationDetailObject>,
  ) {
    let group = new UntypedFormGroup({
      name: new UntypedFormControl(translation?.name ?? null, [Validators.required]),
    });
    this.translationsGroup.setControl(locale, group);
    return group;
  }
}
