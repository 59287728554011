<h2 mat-dialog-title>Export Product Report</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="product_report_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-dashboard-panel class="p-0" [panelLoading]="loading$ | async">
      <x-form-panel>
        <x-locale-select label="Locale" formControlName="locale"></x-locale-select>
        <x-boolean-filter-field
          [nullable]="false"
          label="Include Attributes"
          formControlName="includeAttributes"
        ></x-boolean-filter-field>
      </x-form-panel>
    </x-dashboard-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button [disabled]="loading$ | async" type="button" (click)="dialog.close()">
    Cancel
  </button>
  <button
    mat-button
    [disabled]="loading$ | async"
    type="submit"
    color="primary"
    form="product_report_form"
  >
    Download Report
  </button>
</div>
