<!-- [panelError]="formObject.errors"
    [panelLoading]="formObject.loading" -->
<h2 mat-dialog-title>Product Option Form</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="product_option_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  *ngIf="formObject$ | async as formObject"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <div class="row">
        <mat-form-field class="col">
          <mat-label>Product Option Name</mat-label>
          <input matInput type="text" formControlName="name" required autocomplete="disabled" />
          <mat-hint>Only visible to administrators</mat-hint>
        </mat-form-field>
        <ng-container *ngFor="let locale of locales$ | async" formGroupName="translations">
          <mat-form-field
            class="col"
            *ngIf="getTranslationGroup(locale.id) as translationGroup"
            [formGroup]="translationGroup"
          >
            <mat-label>{{ locale.id }}</mat-label>
            <input matInput type="text" formControlName="name" required autocomplete="disabled" />
          </mat-form-field>
        </ng-container>
      </div>
      <h4 class="py-4">Values</h4>
      <div cdkDropList (cdkDropListDropped)="valueDropped($event)">
        <ng-container
          *ngFor="let control of valuesGroup.controls; let i = index"
          formGroupName="values"
          class="option-value-list"
        >
          <div class="row" [formGroupName]="i" cdkDrag>
            <mat-form-field class="col">
              <mat-label>Option Value Name</mat-label>
              <input matInput type="text" formControlName="name" required autocomplete="disabled" />
              <mat-hint>Only visible to administrators</mat-hint>
            </mat-form-field>
            <ng-container *ngFor="let locale of locales$ | async">
              <mat-form-field
                class="col"
                *ngIf="getValueTranslationGroup(locale.id, i) as valueTranslationGroup"
                [formGroup]="valueTranslationGroup"
              >
                <mat-label>{{ locale.id }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="name"
                  required
                  autocomplete="disabled"
                />
              </mat-form-field>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col">&nbsp;</div>
        <div class="col" *ngFor="let locale of locales$ | async">
          <button mat-button type="button" (click)="copyCanonicalTo(locale.id)">
            Copy canonical to {{ locale.id }}
          </button>
        </div>
      </div>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="button" (click)="addOption()">Add Option</button>
  <button mat-button type="submit" color="primary" form="product_option_form">Save</button>
</div>
