import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductAttributeDetailObject, ProductAttributeService } from '@x/ecommerce/domain-client';
import { ChannelContext } from '@x/ecommerce/domain-data';
import { CreateProductAttributeInput, UpdateProductAttributeInput } from '@x/schemas/ecommerce';
import { firstValueFrom } from 'rxjs';
import { ProductAttributeFormGroup } from './product-attribute.form-group';

export interface ProductAttributeFormDialogData {
  id?: number;
}

export interface ProductAttributeFormDialogResult {
  result?: ProductAttributeDetailObject;
}

@Component({
  selector: 'x-product-attribute-form-dialog',
  templateUrl: './product-attribute-form-dialog.component.html',
  styleUrls: ['./product-attribute-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAttributeFormDialogComponent implements OnInit {
  formGroup = new ProductAttributeFormGroup();

  localeOptions$ = this.channelContext.getLocaleOptionsInAllChannels();

  isUpdate = false;

  constructor(
    public dialog: MatDialogRef<ProductAttributeFormDialogComponent>,
    private channelContext: ChannelContext,
    @Inject(MAT_DIALOG_DATA)
    private data: ProductAttributeFormDialogData,
    private productAttributeService: ProductAttributeService,
  ) {}

  ngOnInit(): void {
    this.reset().then();
  }

  async reset() {
    let value: ProductAttributeDetailObject | undefined;

    if (this.data.id) {
      this.isUpdate = true;
      value = await this.fetch(this.data.id);
    } else {
      this.isUpdate = false;
    }

    this.formGroup.init({ value });
  }

  async submit() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.valid) {
      return;
    }

    const data = {
      ...this.formGroup.value,
      translations: Object.keys(this.formGroup.value.translations).map((k) => {
        return {
          locale: k,
          name: this.formGroup.value.translations[k].name,
        };
      }),
    };

    let result: ProductAttributeDetailObject | undefined;

    if (this.data.id) {
      result = await this.update(this.data.id, data);
    } else {
      result = await this.create(data);
    }

    this.dialog.close({ result });
  }

  copyCanonicalTo(locale: string) {
    this.formGroup.getTranslationGroup(locale).patchValue({
      name: this.formGroup.get('name')?.value,
    });
  }

  private fetch(id: number): Promise<ProductAttributeDetailObject> {
    return firstValueFrom(this.productAttributeService.fetchDetail(id));
  }

  private create(data: CreateProductAttributeInput): Promise<ProductAttributeDetailObject> {
    return firstValueFrom(this.productAttributeService.create(data));
  }

  private update(
    id: number,
    data: UpdateProductAttributeInput,
  ): Promise<ProductAttributeDetailObject> {
    return firstValueFrom(this.productAttributeService.update(id, data));
  }
}
