<mat-form-field appearance="fill" style="width: 100%">
  <mat-label>
    <fa-icon matTextPrefix icon="layer-group"></fa-icon>
    Filter Taxon
  </mat-label>
  <x-model-autocomplete-chips
    [formControl]="taxonIdsControl"
    [dataSource]="autoDataSource"
    [transformer]="transformer"
    [displayWith]="autoDataSource.displayFn"
  >
    <ng-template xModelAutocompleteOptionDef let-taxon>
      {{ taxon.name }}
    </ng-template>
  </x-model-autocomplete-chips>
  <button mat-icon-button matIconSuffix [matMenuTriggerFor]="menu">
    <fa-icon icon="cog"></fa-icon>
  </button>
  <mat-hint>
    {{ mainTaxonControl.value ? 'Main Taxon Only' : 'Associated Taxon' }} |
    {{ includeDescendentsControl.value ? 'Including Descendents' : 'Excluding Descendents' }}
  </mat-hint>
</mat-form-field>

<mat-menu #menu>
  <button mat-menu-item (click)="toggleMainTaxon()">
    <mat-checkbox [checked]="!mainTaxonControl.value"></mat-checkbox>
    <span>Include Associated Taxons</span>
  </button>
  <button mat-menu-item (click)="toggleIncludeDescendents()">
    <mat-checkbox [checked]="includeDescendentsControl.value"></mat-checkbox>
    <span>Including Descendents</span>
  </button>
</mat-menu>
