<h2 mat-dialog-title>Clone Product</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="product_clone_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Cloned Product Name</mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="disabled" />
        <mat-hint>Only visible to administrators</mat-hint>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="product_clone_form">Clone</button>
</div>
