<mat-tree
  *ngIf="dataSource"
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  [trackBy]="trackById"
  class="taxon-tree"
>
  <!-- This is the tree node template for leaf nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node" class="taxon-node leaf">
    <div class="taxon-node-parent">
      <div class="taxon-node-position-handle">
        <fa-icon [icon]="node.taxon?.badge ? 'certificate' : 'layer-group'"></fa-icon>
      </div>
      <ng-container
        [ngTemplateOutlet]="treeNodeDef?.template || defaultNodeItemTemplate"
        [ngTemplateOutletContext]="{ $implicit: node }"
      ></ng-container>
      <ng-container
        *ngIf="treeNodeActionDef"
        [ngTemplateOutlet]="treeNodeActionDef.template"
        [ngTemplateOutletContext]="{ $implicit: node }"
      ></ng-container>
    </div>
  </mat-nested-tree-node>

  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="taxon-node branch">
    <div class="taxon-node-parent">
      <button mat-icon-button matTreeNodeToggle>
        <fa-icon [icon]="treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"></fa-icon>
      </button>
      <ng-container
        [ngTemplateOutlet]="treeNodeDef?.template || defaultNodeItemTemplate"
        [ngTemplateOutletContext]="{ $implicit: node }"
      ></ng-container>
      <ng-container
        *ngIf="treeNodeActionDef"
        [ngTemplateOutlet]="treeNodeActionDef.template"
        [ngTemplateOutletContext]="{ $implicit: node }"
      ></ng-container>
    </div>

    <div class="taxon-node-children" [class.hidden]="!treeControl.isExpanded(node)">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
<ng-template #defaultNodeItemTemplate let-node>
  <div class="taxon-title">{{ node.taxon.name }}</div>
</ng-template>
