import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';

interface TimeOption {
  label: string;
  value: string;
}

const TIME_OPTIONS = Array.from({ length: 24 }, (v, k) => k).reduce((options, h) => {
  Array.from({ length: 4 }, (v, k) => k * 15).forEach((m) => {
    const value = String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0');
    options.push({ label: value, value });
  });

  return options;
}, [] as TimeOption[]);

export interface ProductSchedulePublishDialogData {
  publishDate?: DateTime;
}
export interface ProductSchedulePublishDialogResult {
  data?: { publishDate: DateTime };
}

@Component({
  selector: 'x-product-schedule-publish-dialog',
  templateUrl: 'product-schedule-publish-dialog.component.html',
  styleUrls: ['./product-schedule-publish-dialog.component.scss'],
})
export class ProductSchedulePublishDialogComponent {
  publishDate: DateTime | null = this.data.publishDate ?? null;

  time: string = TIME_OPTIONS[0].value;

  timeOptions = TIME_OPTIONS;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ProductSchedulePublishDialogData,
    public dialog: MatDialogRef<
      ProductSchedulePublishDialogComponent,
      ProductSchedulePublishDialogResult
    >,
  ) {}

  save() {
    if (this.publishDate == null) return;

    const [h, m] = this.time.split(':');

    const publishDate = this.publishDate.set({
      hour: parseInt(h),
      minute: parseInt(m),
    });

    this.dialog.close({
      data: {
        publishDate,
      },
    });
  }

  cancel() {
    this.dialog.close();
  }
}
