import { NgModule } from '@angular/core';
import { LocaleModule } from '@x/common/locale';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { ProductAttributeFormDialogComponent } from './components/product-attribute-form/product-attribute-form-dialog.component';
import { ProductCloneDialogComponent } from './components/product-clone-dialog/product-clone-dialog.component';
import { ProductCreateDialogComponent } from './components/product-create-dialog/product-create-dialog.component';
import { ProductTaxonFilterFieldComponent } from './components/product-index/product-taxon-filter-field.component';
import { ProductOptionFormDialogComponent } from './components/product-option-form/product-option-form-dialog.component';
import { ProductSchedulePublishDialogComponent } from './components/product-schedule-publish-dialog/product-schedule-publish-dialog.component';
import { ProductVariantAutocompleteChipsComponent } from './components/product-variant-autocomplete-chips/product-variant-autocomplete-chips.component';
import { ProductVariantAutocompleteComponent } from './components/product-variant-autocomplete/product-variant-autocomplete.component';
import { TaxonCreateDialogComponent } from './components/taxon-form/taxon-create-dialog.component';
import {
  TaxonNodeActionDefDirective,
  TaxonNodeDefDirective,
  TaxonTreeComponent,
} from './components/taxon-tree/taxon-tree.component';
import { ProductPriorityLabelPipe } from './pipes/product-priority-label.pipe';
import { PRODUCTS_LANGUAGE } from './products.language';
import { ProductDialogService } from './services/product-dialog.service';
import { TaxonDialogService } from './services/taxon-dialog.service';
// import { ProductTableComponent } from './components/product-table/product-table.component';
import { ProductAssociationFormDialogComponent } from './components/product-association-form-dialog/product-association-form-dialog.component';
import { ProductExportDialogComponent } from './components/product-export-dialog/product-export-dialog.component';
import { ProductWishlistItemsTableComponent } from './components/product-wishlist-items-table/product-wishlist-items-table.component';

@NgModule({
  imports: [EcommerceAdminCoreModule, LocaleModule.forChild([PRODUCTS_LANGUAGE])],
  declarations: [
    TaxonCreateDialogComponent,
    ProductCreateDialogComponent,
    ProductCloneDialogComponent,
    ProductOptionFormDialogComponent,
    TaxonTreeComponent,
    TaxonNodeDefDirective,
    ProductAttributeFormDialogComponent,
    TaxonNodeActionDefDirective,
    ProductTaxonFilterFieldComponent,
    ProductSchedulePublishDialogComponent,
    ProductPriorityLabelPipe,
    ProductVariantAutocompleteChipsComponent,
    ProductVariantAutocompleteComponent,
    ProductExportDialogComponent,
    ProductWishlistItemsTableComponent,
    // ProductTableComponent,
    ProductAssociationFormDialogComponent,
  ],
  providers: [ProductDialogService, TaxonDialogService],
  exports: [
    TaxonTreeComponent,
    TaxonNodeDefDirective,
    TaxonNodeActionDefDirective,
    ProductTaxonFilterFieldComponent,
    ProductPriorityLabelPipe,
    ProductVariantAutocompleteChipsComponent,
    ProductVariantAutocompleteComponent,
    ProductWishlistItemsTableComponent,
  ],
})
export class ProductsSharedModule {}
