import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { ProductWishlistItemRowObject } from '@x/ecommerce/domain-client';
import { ProductWishlistItemFilterInput } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-product-wishlist-items-table',
  templateUrl: './product-wishlist-items-table.component.html',
  styleUrls: ['./product-wishlist-items-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ProductWishlistItemsTableComponent {
  @Input()
  view: DataTableView<ProductWishlistItemRowObject, ProductWishlistItemFilterInput, any, number>;

  @Output()
  removeItem = new EventEmitter<ProductWishlistItemRowObject>();
}
