import { LanguageDeclaration } from '@x/common/locale';

export const PRODUCTS_LANGUAGE: LanguageDeclaration = {
  product: {
    attribute: {
      types: {
        MARKDOWN: 'Markdown',
      },
    },
    variation: {
      SINGLE: 'Single',
      OPTION: 'Option',
    },
  },
  productAttribute: {
    column: {
      id: 'ID',
      position: 'Position',
      name: 'Attribute Name',
      type: 'Attribute Type',
      createdAt: 'Created Date',
      updatedAt: 'Updated Date',
    },
    sort: {
      id: 'ID',
      position: 'Position',
      name: 'Name',
      type: 'Type',
      createdAt: 'Created Date',
      updatedAt: 'Updated Date',
    },
  },
};
