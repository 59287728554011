import { ChangeDetectionStrategy, Component, OnDestroy, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TaxonAutocompleteDataSource, TaxonIdTransformer } from '@x/ecommerce/domain-data';
import { Subject } from 'rxjs';

@Component({
  selector: 'x-product-taxon-filter-field',
  templateUrl: 'product-taxon-filter-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TaxonIdTransformer, TaxonAutocompleteDataSource],
  host: {
    class: 'x-product-taxon-filter-field',
  },
  preserveWhitespaces: false,
})
export class ProductTaxonFilterFieldComponent implements ControlValueAccessor, OnDestroy {
  taxonIdsControl = new UntypedFormControl([]);
  includeDescendentsControl = new UntypedFormControl(true);
  mainTaxonControl = new UntypedFormControl(true);

  formGroup = new UntypedFormGroup({
    taxonIds: this.taxonIdsControl,
    includeDescendents: this.includeDescendentsControl,
    mainTaxon: this.mainTaxonControl,
  });

  private _destroy$ = new Subject<void>();

  constructor(
    public autoDataSource: TaxonAutocompleteDataSource,
    public transformer: TaxonIdTransformer,
    @Optional()
    @Self()
    public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  writeValue(obj: any): void {
    this.formGroup.setValue(
      {
        taxonIds: obj?.taxonIds ?? [],
        includeDescendents: obj?.includeDescendents ?? true,
        mainTaxon: obj?.mainTaxon ?? true,
      },
      {
        emitEvent: false,
      },
    );
  }

  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe((value) => {
      if (!value.taxonIds.length) {
        fn(null);
      } else {
        fn(value);
      }
    });
  }

  registerOnTouched(fn: any): void {}

  toggleMainTaxon() {
    this.mainTaxonControl.setValue(!this.mainTaxonControl.value);
  }

  toggleIncludeDescendents() {
    this.includeDescendentsControl.setValue(!this.includeDescendentsControl.value);
  }
}
