import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TaxonService } from '@x/ecommerce/domain-client';
import { TaxonAutocompleteDataSource, TaxonIdTransformer } from '@x/ecommerce/domain-data';

export interface TaxonCreateDialogData {
  parentId?: number;
}

export interface TaxonCreateDialogResult {
  data?: { id: number; name: string };
}

@Component({
  selector: 'x-taxon-create-dialog',
  templateUrl: 'taxon-create-dialog.component.html',
  styleUrls: ['taxon-create-dialog.component.scss'],
  providers: [TaxonAutocompleteDataSource, TaxonIdTransformer],
})
export class TaxonCreateDialogComponent implements OnInit {
  taxonControl = new UntypedFormControl(this.data.parentId ?? null);

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
    parent: this.taxonControl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: TaxonCreateDialogData,
    private taxonService: TaxonService,
    public dialog: MatDialogRef<TaxonCreateDialogData, TaxonCreateDialogResult>,
    public taxonDatasource: TaxonAutocompleteDataSource,
    public taxonIdTransformer: TaxonIdTransformer,
  ) {}

  ngOnInit() {}

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    this.taxonService
      .create({
        name: this.formGroup.controls.name.value,
        parentId: this.taxonControl?.value || null,
      })
      .subscribe((taxon) => {
        this.dialog.close({ data: taxon });
      });
  }
}
