<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <fa-icon icon="list" matPrefix></fa-icon>
  <x-model-autocomplete-chips
    [formControl]="control"
    [dataSource]="productVariantAutocompleteDatasource"
    [displayWith]="productVariantAutocompleteDatasource.displayFn"
    [transformer]="productVariantIdTransformer"
    placeholder="Search product..."
  ></x-model-autocomplete-chips>
</mat-form-field>
