import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductService } from '@x/ecommerce/domain-client';
import { ProductOptionAutocompleteDataSource } from '@x/ecommerce/domain-data';
import { CloneProductInput } from '@x/schemas/ecommerce';

export interface ProductCloneDialogData {
  clone_id: number;
}

export interface ProductCloneDialogResult {
  data?: { id: number; name: string };
}

@Component({
  selector: 'x-product-clone-dialog',
  templateUrl: 'product-clone-dialog.component.html',
  providers: [ProductOptionAutocompleteDataSource],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ProductCloneDialogComponent implements OnInit {
  taxonControl = new UntypedFormControl(null, [Validators.required]);

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]),
  });

  constructor(
    public dialog: MatDialogRef<ProductCloneDialogComponent, ProductCloneDialogResult>,
    private productsService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: ProductCloneDialogData,
  ) {}

  ngOnInit() {}

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    const form = this.formGroup.value;

    const data: CloneProductInput = {
      clone_id: this.data.clone_id,
      name: form.name,
    };

    this.productsService.clone(data).subscribe((data) => {
      this.dialog.close({ data });
    });
  }
}
