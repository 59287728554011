<x-data-table [view]="view" [selectable]="false" [activatable]="false">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>

  <ng-template xDataColumnDef="product" let-row>
    <a
      [routerLink]="['/admin/products', 'detail', row.product.id]"
      matTooltip="View Product"
      color="default"
    >
      {{ row.product.name }}
    </a>
  </ng-template>

  <ng-template xDataColumnDef="user" let-row>
    <x-user-pill [user]="row.user"></x-user-pill>
  </ng-template>

  <ng-template xDataColumnDef="timestamps" let-row>
    <div>
      Created:
      <b>{{ row.createdAt | datetime : 'short' }}</b>
    </div>
    <div>
      Updated:
      <b>{{ row.updatedAt | datetime : 'short' }}</b>
    </div>
  </ng-template>

  <a
    x-data-button
    *xDataAction="'remove'; let row; primary: true"
    (click)="removeItem.emit(row)"
    label="Remove Item"
    icon="trash"
  ></a>
</x-data-table>
