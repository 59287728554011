<h2 mat-dialog-title>Create Taxon</h2>
<form
  #form="ngForm"
  mat-dialog-content
  [formGroup]="formGroup"
  id="taxon_create_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill" xField>
        <mat-label>Taxon Name</mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="disabled" />
        <mat-error xFormError="name"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" xField>
        <mat-label>Parent Taxon</mat-label>
        <x-model-autocomplete
          placeholder="Search taxon..."
          [dataSource]="taxonDatasource"
          [displayWith]="taxonDatasource.displayFn"
          formControlName="parent"
          [transformer]="taxonIdTransformer"
        ></x-model-autocomplete>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <x-pill *ngIf="form.submitted && formGroup.invalid" color="warn">Form Invalid</x-pill>
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="taxon_create_form">Create</button>
</div>
