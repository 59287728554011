<h2 mat-dialog-title>Schedule Product Publish</h2>
<ng-container mat-dialog-content>
  <mat-calendar [(selected)]="publishDate"></mat-calendar>
  <mat-form-field appearance="fill">
    <mat-label>Time</mat-label>
    <mat-select [(ngModel)]="time" style="min-width: 60px" tabindex="-1">
      <mat-option *ngFor="let time of timeOptions" [value]="time.value">
        {{ time.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
<div mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="save()" color="primary">Schedule</button>
</div>
